/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';

export const CESSIONS_SUMMARY = gql`
  query cessionsSummary($companyId: Int!, $currency: String) {
    cessionsSummary(companyId: $companyId, currency: $currency) {
      amount {
        ...MoneyFields
      }
      invoiceCount
      debtCount
      debtAmount {
        ...MoneyFields
      }
      availableSurplus {
        ...MoneyFields
      }
      cantWithdrawSurplus
    }
  }
  ${MoneyFields}
`;
