import { gql } from '@apollo/client';
import MoneyFields from '../fragments/money/money-fields';

export const SimpleAmortizationScheduleFragment = gql`
  fragment SimpleAmortizationScheduleFragment on AmortizationScheduleType {
    id
    createdAt
    globalAppId
    status
    interestRate
    defaultRate
  }
`;

export const InstallmentFields = gql`
  fragment InstallmentFields on InstallmentType {
    id
    createdAt
    startingDate
    expirationDate
    interestAmount {
      ...MoneyFields
    }
    principalAmount {
      ...MoneyFields
    }
    accumulatedInterest {
      ...MoneyFields
    }
    installmentAmountToPay {
      ...MoneyFields
    }
    interestAmountToPay {
      ...MoneyFields
    }
    amortizationToPay {
      ...MoneyFields
    }
  }
  ${MoneyFields}
`;

export const InstallmentManagerFields = gql`
  fragment InstallmentManagerFields on InstallmentManagerType {
    id
    status
    debtAmount {
      ...MoneyFields
    }
    totalAmountToPay {
      ...MoneyFields
    }
    status
    paymentDatetime
  }
  ${MoneyFields}
`;
